<template>
  <p-mega-menu
      :model="items"
      orientation="vertical"
      :class="{ 'expandMegamenu' : expandMenu }"
  >
    <template #start>
      <div v-show="!expandMenu" style="border-bottom: 1px solid black;">
        <div class="flex justify-content-center my-2">
          <img
              src="../assets/companylogo.png"
              alt="Company Logo"
              width="25"
              height="25"
              class="menuLogo"
              @click="expandNavigationMenu"
              v-tooltip.right="'Expand Menu'"
          />
        </div>
      </div>
      <div v-show="expandMenu" style="border-bottom: 1px solid black;">
        <div class="flex justify-content-center my-2">
          <img
              src="../assets/companylogo.png"
              alt="Company Logo"
              width="50"
              height="25"
              class="menuLogo"
              @click="expandNavigationMenu"
              v-tooltip.right="'Collapse Menu'"
          />
        </div>
      </div>
    </template>
  </p-mega-menu>
</template>

<script>
import MegaMenu from "primevue/megamenu"
import Permissions from "../services/PermissionsService"

export default {
  name: "AppNav",
  components: {
    "p-mega-menu" : MegaMenu,
  },
  data () {
    return {
      expandMenu: false,
      items : [
        {
          label: "Dashboard",
          icon: "fa-solid fa-house",
          to: { name: "Dashboard" },
        },
        {
          label: "Items",
          icon: "fa-solid fa-cube",
          to: { name: "Items" },
          visible: () => Permissions.checkPageView("Items")
        },
        {
          label: "Picking logs",
          icon: "fa-solid fa-clipboard-user",
          to: { name: "Picking Logs" },
          visible: () => Permissions.checkPageView("Picking Logs")
        },
        {
          label: "Putaway logs",
          icon: "fa-solid fa-boxes-stacked",
          to: { name: "Putaway Logs" },
          visible: () => Permissions.checkPageView("Putaway Logs")
        },
        {
          label: "Users",
          icon: "fa-solid fa-user",
          to: { name: "Users" },
          visible: () => Permissions.checkPageView("Users")
        },
        {
          label: "Logout",
          icon: "fa-solid fa-right-from-bracket",
          command: () => this.logout(),
        }
      ]
    }
  },
  methods: {
    logout () {
      this.$store.dispatch("logout")
    },
    expandNavigationMenu () {
      this.expandMenu = !this.expandMenu
    }
  },
}
</script>

<style scoped>
.p-megamenu.p-megamenu-vertical {
  width: 2.5rem;
  padding: 0;
  /*background-color: var(--primary-color);*/
  border-left: 0;
  border-top: 0;
  border-right: 1px solid black;
  border-bottom: 0;
  border-radius: 0;
}

::v-deep(.p-megamenu-start) {
  padding-top: 4px;
  background-color: white;
}

.expandMegamenu {
  width: 11rem !important;
}

.menuLogo {
  background-color: white;
}

.menuLogo:hover {
  cursor: pointer;
}

::v-deep(.p-menuitem-text) {
  margin-left: 30px;
  white-space: nowrap;
  /*color: var(--primary-color-text) !important;*/
}

::v-deep(.p-menuitem-icon) {
  width: 18px;
  height: 18px;
  position: absolute;
  text-align: center;
  /*color: var(--primary-color-text) !important;*/
}

::v-deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
  padding-left: 10px !important;
}
</style>