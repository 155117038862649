import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// PrimeVue
import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import ConfirmationService from "primevue/confirmationservice"
import Tooltip from "primevue/tooltip"
import "primevue/resources/primevue.min.css" // Core CSS
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "primevue/resources/themes/saga-blue/theme.css"

// Font Awesome
import "@fortawesome/fontawesome-free/css/all.min.css"

// Method for chris to switch API endpoints on built versions of the app hosted on AWS
const fetchData = async () => {
    const resp = await fetch("/kstore.config.json")
    let backendAPI = await resp.json()

    window.baseUrl = backendAPI.APP_API_URL

    // Create and setup app
    const app = createApp(App)

    app.use(store)
    app.use(router)
    app.use(PrimeVue, { ripple: true })
    app.use(ToastService)
    app.use(ConfirmationService)

    app.directive("tooltip", Tooltip)

    app.mount("#app")
}
fetchData()
