<template>
  <div v-if="this.$store.getters.loggedIn" class="block-content">
    <div style="height: 100vh; overflow: hidden;">
      <div class="flex min-h-screen relative lg:static">
        <!-- App navigation layout -->
        <app-navigation-bar class="z-5"/>

        <!-- Content layout -->
        <div class="max-h-screen flex flex-column relative flex-auto overflow-auto">
          <div class="px-2 flex flex-column flex-auto">
            <p-toast position="top-center"/>
            <p-confirm-dialog/>
            <router-view />
            <p class="text-center footerText">K-Store Pick and Putaway</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-view v-else />

</template>

<script>
import AppNavigationBar from "@/components/AppNavigationBar"
import Toast from "primevue/toast"
import ConfirmDialog from "primevue/confirmdialog"

export default {
  components: {
    AppNavigationBar,
    "p-toast" : Toast,
    "p-confirm-dialog" : ConfirmDialog
  },
  created () {
    const userString = localStorage.getItem("user")

    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit("SET_USER_DATA", userData)
    }
  },
}
</script>

<style>
/* Laptop styling */
@media only screen and (max-height: 900px) {
  html {
    font-size: 0.85rem !important;
  }
}

body {
  font-family: var(--font-family); /* Set font to variable from prime vue theme */
  margin: 0;
  background-color: var(--surface-ground);
}

a {
  color: var(--primary-color)
}

.active {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.active.status-active {
  border: 1px solid #256029;
  background: #C8E6C9;
  color: #256029;
}

.active.status-inactive {
  border: 1px solid #C63737;
  background: #FFCDD2;
  color: #C63737;
}

.p-toast {
  opacity: 1 !important;
}

.p-dialog-header {
  padding-bottom: 0 !important;
}
</style>
