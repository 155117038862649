import { createRouter, createWebHistory } from "vue-router"
import LoginView from "../views/LoginView.vue"
import PermissionsService from "@/services/PermissionsService"

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
        import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/items",
    name: "Items",
    component: () =>
        import(/* webpackChunkName: "group-items" */ "../views/items/ItemsView"),
    meta: { pageCategory: "Items", requiresAuth: true }
  },
  {
    path: "/items/edit/:id",
    name: "Item Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-items" */ "../views/items/EditItemsView"),
    meta: { pageCategory: "Items", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/picking-logs",
    name: "Picking Logs",
    component: () =>
        import(/* webpackChunkName: "group-logs" */ "../views/PickingLogsView.vue"),
    meta: { pageCategory: "Picking Logs", requiresAuth: true }
  },
  {
    path: "/putaway-logs",
    name: "Putaway Logs",
    component: () =>
        import(/* webpackChunkName: "group-logs" */ "../views/PutawayLogsView.vue"),
    meta: { pageCategory: "Putaway Logs", requiresAuth: true }
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
        import(/* webpackChunkName: "users" */ "../views/UsersView.vue"),
    meta: { pageCategory: "Users", requiresAuth: true }
  },
  {
    path: "/:catchAll(.*)", // Match all routes that do not match an existing route
    name: "Not found",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/NotFoundErrorView")
  },
  {
    path: "/network-error",
    name: "Network Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/NetworkErrorView")
  },
  {
    path: "/token-error",
    name: "Token Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/JwtTokenErrorView")
  },
  {
    path: "/permission-error",
    name: "Permission Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/PermissionErrorView")
  },
  {
    path: "/api-error",
    name: "API Error",
    component: () =>
        import(/* webpackChunkName: "error" */ "../views/errors/ApiErrorView")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  /* Save a users scroll position between pages */
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition;
    } else{
      return { top: 0 };
    }
  }
})

// Create a general route guard:
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user")

  // If the route requires authentication and user is not logged in:
  if (to.meta.requiresAuth && !loggedIn) {
    // Redirect to log in:
    next("/")
  }

  // If the route requires authentication and write access:
  else if (to.meta.requiresAuth && to.meta.requiresWriteAccess) {
    let pageView = PermissionsService.checkPageView(to.meta.pageCategory)
    let pageEdit = PermissionsService.checkPageEdit(to.meta.pageCategory)

    if (pageView && pageEdit) {
      next()
    }
    else {
      // Redirect to permission error page:
      next("permission-error")
    }
  }

  // If the route requires authentication and has a page category:
  else if (to.meta.requiresAuth && to.meta.pageCategory) {
    let pageView = PermissionsService.checkPageView(to.meta.pageCategory)

    if (pageView) {
      next()
    }
    else {
      // Redirect to permission error page:
      next("permission-error")
    }
  }

  // If a user is logged in and tries to go to the login route via the URL:
  else if (loggedIn && to.name === "Login") {
    next("dashboard")
  }

  else {
    // Continue to route:
    next()
  }
})


export default router;
